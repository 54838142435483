export function parseMoney(input: string): number | null {
  if (typeof input !== 'string') return null

  // Remove any currency symbols and trim spaces
  const cleanedInput = input.replace(/[^0-9.,-]/g, '').trim()

  // Handle numbers like "1.000,00" or "1,000.00"
  const hasComma = cleanedInput.includes(',')
  const hasDot = cleanedInput.includes('.')

  let normalizedInput: string

  if (hasComma && hasDot) {
    // Assume the last separator is the decimal separator
    const lastCommaIndex = cleanedInput.lastIndexOf(',')
    const lastDotIndex = cleanedInput.lastIndexOf('.')

    if (lastCommaIndex > lastDotIndex) {
      // Treat comma as decimal separator and dot as thousand separator
      normalizedInput = cleanedInput.replace(/\./g, '').replace(',', '.')
    } else {
      // Treat dot as decimal separator and comma as thousand separator
      normalizedInput = cleanedInput.replace(/,/g, '')
    }
  } else if (hasComma) {
    // If there's only a comma, assume it's the decimal separator in European format
    normalizedInput = cleanedInput.replace(',', '.')
  } else {
    // If there's only a dot, assume it's the decimal separator in standard format
    normalizedInput = cleanedInput
  }

  // Parse the cleaned string to a float
  const result = parseFloat(normalizedInput)
  return isNaN(result) ? null : result
}
