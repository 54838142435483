import { $channelId } from '@channel/channel.model'
import { createMutation } from '@farfetched/core'
import { createAction } from 'effector-action'
import { createSalesServiceEffect } from '~/api/sales'
import type { Report } from '../../types'

export const removeSalesReportMutation = createMutation({
  effect: createSalesServiceEffect({
    method: 'delete',
    url: '/sales/analytic/{report_id}',
  }),
})

export const removeSalesReport = createAction({
  target: { startMutation: removeSalesReportMutation.start },
  source: { channelId: $channelId },
  fn: (target, { channelId }, { id }: Pick<Report, 'id'>) => {
    if (channelId) {
      target.startMutation({
        params: {
          path: {
            report_id: id,
          },
          query: {
            channel_id: channelId,
          },
        },
      })
    }
  },
})
