import { $channelId, channelChanged } from '@channel/channel.model'
import type { RemoteOperationParams } from '@farfetched/core'
import { concurrency, createQuery, keepFresh } from '@farfetched/core'
import { invoke } from '@withease/factories'
import type { StoreValue } from 'effector'
import { createStore, sample } from 'effector'
import { createAction } from 'effector-action'
import { createSalesServiceEffect } from '~/api/sales'
import { createDisclosureAtom } from '~/shared/lib/factories/create-disclosure'
import {
  createSaleReportMutation,
  modalDisclosureAtom,
} from '../create_report/model'
import { removeSalesReportMutation } from '../remove_report/model'

export const reportsDisclosureAtom = invoke(() => createDisclosureAtom())

export const reportsListQuery = createQuery({
  effect: createSalesServiceEffect({
    method: 'get',
    url: '/sales/analytic',
  }),
  initialData: [],
})

concurrency(reportsListQuery, {
  strategy: 'TAKE_LATEST',
})

keepFresh(reportsListQuery, {
  triggers: [
    createSaleReportMutation.finished.success,
    removeSalesReportMutation.finished.success,
  ],
})

sample({
  clock: reportsDisclosureAtom.opened,
  source: {
    channelId: $channelId,
    reportsStatus: reportsListQuery.$status,
  },
  filter: ({ reportsStatus }) => {
    return reportsStatus === 'initial'
  },
  fn: ({ channelId }) =>
    ({
      params: {
        query: {
          channel_id: channelId!,
        },
      },
    }) satisfies RemoteOperationParams<typeof reportsListQuery>,
  target: reportsListQuery.refresh,
})

type Report = StoreValue<(typeof reportsListQuery)['$data']>[number]

export const $selectedSalesReport = createStore<Report | null>(null)
export const resetSelectedReport = $selectedSalesReport.reinit
export const reportSelected = createAction({
  target: $selectedSalesReport,
  source: { reports: reportsListQuery.$data },
  fn: (target, { reports }, reportId: Report['id']) => {
    const report = reports.find(r => r.id === reportId)

    if (report) {
      target(report)
    }
  },
})

sample({
  clock: modalDisclosureAtom.opened,
  target: reportsDisclosureAtom.close,
})

sample({
  clock: removeSalesReportMutation.finished.success,
  target: resetSelectedReport,
})

sample({
  clock: channelChanged,
  target: [reportsListQuery.reset, $selectedSalesReport.reinit] as const,
})
