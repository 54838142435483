import { $channelId } from '@channel/channel.model'
import { createMutation } from '@farfetched/core'
import { createAction } from 'effector-action'
import { createSalesServiceEffect } from '~/api/sales'
import type { Report } from '../../../types'
import { $selectedSalesReport } from '../../reports_list/model'

export const updateReportBudgetMutation = createMutation({
  effect: createSalesServiceEffect({
    method: 'patch',
    url: '/sales/analytic/report',
  }),
})

export const salesReportBudgetChanged = createAction({
  target: { startMutation: updateReportBudgetMutation.start },
  source: { channelId: $channelId, report: $selectedSalesReport },
  fn: (target, { channelId, report }, { budget }: Pick<Report, 'budget'>) => {
    if (channelId && report) {
      target.startMutation({
        body: {
          channel_id: channelId,
          report_id: report.id,
          budget,
        },
      })
    }
  },
})
