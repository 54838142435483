import { $channelId } from '@channel/channel.model'
import { createMutation } from '@farfetched/core'
import { createAction } from 'effector-action'
import { createSalesServiceEffect } from '~/api/sales'
import type { Report } from '../../../types'
import { $selectedSalesReport } from '../../reports_list/model'

export const updateReportNameMutation = createMutation({
  effect: createSalesServiceEffect({
    method: 'patch',
    url: '/sales/analytic/report',
  }),
})

export const reportNameChanged = createAction({
  target: { startMutation: updateReportNameMutation.start },
  source: { channelId: $channelId, report: $selectedSalesReport },
  fn: (target, { channelId, report }, { name }: Pick<Report, 'name'>) => {
    if (channelId && report) {
      target.startMutation({
        body: {
          channel_id: channelId,
          report_id: report.id,
          name,
        },
      })
    }
  },
})
