import { $channelId } from '@channel/channel.model'
import { createMutation } from '@farfetched/core'
import { createAction } from 'effector-action'
import { createSalesServiceEffect } from '~/api/sales'
import type { ReportSource } from '../../../types'
import { $selectedSalesReport } from '../../reports_list/model'

export const updateReportSourcedInviteLinkBudgetMutation = createMutation({
  effect: createSalesServiceEffect({
    method: 'patch',
    url: '/sales/analytic/{report_id}/budget',
  }),
})

export const salesReportSourceBudgetChanged = createAction({
  target: { startMutation: updateReportSourcedInviteLinkBudgetMutation.start },
  source: { report: $selectedSalesReport, channelId: $channelId },
  fn: (
    target,
    { channelId, report },
    { id, budget }: Pick<ReportSource, 'id' | 'budget'>,
  ) => {
    if (channelId && report) {
      target.startMutation({
        params: {
          path: {
            report_id: report.id,
          },
          query: {
            channel_id: channelId,
            source_report_id: id,
            budget,
          },
        },
      })
    }
  },
})
